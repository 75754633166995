// @ts-strict-ignore
import { css } from "@emotion/react";

export const size = {
  desktop: css`
    width: 7.5rem;
    height: 7.5rem;
  `,
  mobile: css`
    width: 4rem;
    height: 4rem;
  `,
};

export const heightAndWidth = customHeightAndWidth => css`
  width: ${customHeightAndWidth}rem;
  height: ${customHeightAndWidth}rem;
`;
